import React, { FC, useCallback, useEffect, useState } from 'react';
import Link from 'next/link';
import { useAuthContext } from 'src/providers/auth';
import Image from 'next/image';

const UserIcon = () => {
  const [menuShown, setMenuShown] = useState<boolean>(false);
  const { uid, signOut } = useAuthContext();

  process?.env?.NODE_ENV === 'development' &&
    console.log('UserIconが再レンダリングされました', menuShown);

  useEffect(() => {
    const event = (mouseEvent: MouseEvent) => {
      const target = mouseEvent.target as HTMLElement | null;
      if (!target || !(mouseEvent.target instanceof HTMLElement)) {
        return;
      }
      if (menuShown && !target.closest('#header-user-icon')) {
        setMenuShown(false);
      }
    };

    document.addEventListener('click', event);

    return () => {
      document.removeEventListener('click', event);
    };
  }, [menuShown]);

  const toggle = useCallback(() => {
    setMenuShown((_prev) => !_prev);
  }, []);

  return (
    <div id='header-user-icon' className='z-10'>
      <div className='relative' onClick={toggle}>
        <div className='rounded-full overflow-hidden w-10 h-10 cursor-pointer z-20'>
          <Image
            src='/img/author-bio-default-96x96.webp'
            alt='著者のアイコン'
            width={40}
            height={40}
          />
        </div>
        {menuShown && (
          <div className='absolute animate-appear opacity-0 top-full right-0 md:right-1/2 translate-x-6 xl:translate-x-1/4'>
            <div className='bg-white rouded-md shadow-xl overflow-hidden w-64 py-2'>
              <ul className='flex flex-col'>
                <li className='hover:bg-slate-100 transition'>
                  <Link href={`/articles/${uid}`}>
                    <a className='px-4 py-3 block'>
                      <div className='text-sm text-slate-800'>ユーザーページへ</div>
                      <div className='text-xs text-slate-500'>あなたのホームページを確認する</div>
                    </a>
                  </Link>
                </li>
                <li className='hover:bg-slate-100'>
                  <Link href={`/account`}>
                    <a className='px-4 py-3 block'>
                      <div className='text-sm text-slate-800'>管理ページへ</div>
                      <div className='text-xs text-slate-500'>kintoneと連携する</div>
                    </a>
                  </Link>
                </li>
                <li className='hover:bg-slate-100 border-t-[1px]'>
                  <a className='px-4 py-3 block cursor-pointer' onClick={signOut}>
                    <div className='text-sm text-slate-800'>ログアウト</div>
                  </a>
                </li>
                <li className='hover:bg-slate-100'>
                  <Link href={`/`}>
                    <a className='px-4 py-3 block'>
                      <div className='text-sm text-slate-800'>kula トップへ</div>
                    </a>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const Component: FC = () => {
  const { uid, signOut } = useAuthContext();

  if (uid) {
    const onClick = async () => {
      await signOut!();
    };

    return (
      <div className='flex items-center gap-2'>
        <Link href='/account'>
          <a className='py-2 text-sm px-2 sm:px-4 hover:bg-indigo-100 text-indigo-600 transition-all rounded cursor-pointer'>
            管理画面へ
          </a>
        </Link>
        <UserIcon />
      </div>
    );
  }

  return (
    <div className='flex gap-4'>
      <Link href='/signup'>
        <a>
          <div className='py-2 px-4 text-sm hover:bg-gray-100 transition-all bg-white rounded-md cursor-pointer'>
            サインアップ
          </div>
        </a>
      </Link>
    </div>
  );
};

const Container: FC = () => {
  const { loading } = useAuthContext();

  if (loading) {
    return null;
  }
  return <Component />;
};

export default Container;
